import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Modal } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function CreateAdPage() {
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [releaseYear, setReleaseYear] = useState("");
  const [isbn, setIsbn] = useState("");
  const [price, setPrice] = useState("");
  const [condition, setCondition] = useState("");
  const [description, setDescription] = useState("");
  const [customAdImg, setCustomAdImg] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [smallId, setSmallId] = useState("");
  const [adImg, setAdImg] = useState("");
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState({});
  const [bookSmallId, setBookSmallId] = useState(null);

  const navigate = useNavigate();

  const handleSelectFile = (e) => setFile(e.target.files[0]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/currentuser", {
          withCredentials: true,
        });

        if (response.data.status) {
          setUser(response.data);
          setZipcode(response.data.zipcode)
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setLoading(true);
  
      let finalAdImg = "https://res.cloudinary.com/bhga4atz/image/upload/v1743427810/cirkellogocropped_qev6qv.png"; // Default image
      let isCustomAdImg = false; // Default to false
  
      if (file) {
        // If a file is selected, upload it
        const data = new FormData();
        data.append("my_file", file);
        const uploadResponse = await axios.post(`/api/uploadadimage`, data);
        finalAdImg = uploadResponse.data.secure_url; // Use the uploaded image URL
        isCustomAdImg = true; // Set customAdImg to true
      }
  
      // Create the ad document
      const response = await axios.post('/api/books/ad', {
        title,
        author,
        isbn,
        price,
        condition,
        description,
        releaseYear,
        zipcode,
        sellerName: user.name,
        sellerId: user.id,
        sellerPhone: user.phone,
        sellerMail: user.email,
        sellerShortId: user.shortUserId,
        adImg: finalAdImg, // Use the final image URL
        customAdImg: isCustomAdImg, // Include the customAdImg boolean
        createdAt: new Date(),
      });
  
      console.log(response.data);
  
      if (response.data.success) {
        setSubmitSuccess(true);
        setSmallId(response.data.smallId);
        setTimeout(() => {
          navigate(`/bøger/annonce/${response.data.shortAdId}?redirected=create-page`);
        }, 5000);
      } else {
        alert("Ad creation failed");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred during ad creation");
    } finally {
      setLoading(false);
    }
  };

  const getRequirementStyle = (requirementMet) => {
    return { color: requirementMet ? 'green' : 'black' };
  };

  return (
    <Container>
      <div className='row mt-5'>
        <div className='col-lg-4'></div>
        <div className='col-lg-4'>
          {!user ? (
            <div>
              <p>Du skal være logget ind for at oprette en annonce</p>
            </div>
          ) : (
            !submitSuccess ? (
              <>
                <div className='form-searchpage-background mb-5'>
                  <h1>Tilføj annonce</h1>
                  <p>Opret en annonce for en bog, du gerne vil sælge på bogbiks.dk</p>
                  
                  <Form onSubmit={handleSubmit} className='mt-2'>
                    <Form.Group controlId="formBasicEmail" className='mb-3'>
                      <Form.Label>Titel</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast titel på bogen"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicTitle" className='mb-3'>
                      <Form.Label>Forfatter</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast titel på bogen"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPrice" className='mb-3'>
                      <Form.Label>Pris</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast pris i kr."
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicZipcode" className='mb-3'>
                      <Form.Label>Postnummer</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast postnummer"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <hr className="my-4" />
<p className="text-muted" style={{ fontSize: '0.9em' }}>
  Felterne nedenfor er valgfri, men annoncer med billede kommer højest i resultater.
</p>


<div className="form-group mb-2 mt-1">
                      <label htmlFor="file">
                        <p>Upload et billede af bogen</p>
                      </label>
                      <div className="custom-file-input">
                        <input
                          id="file"
                          type="file"
                          className="ms-2 signup-image-box"
                          onChange={handleSelectFile}
                          multiple={false}
                        />
                        <Button variant="secondary" onClick={() => document.getElementById('file').click()}>
                          Vælg fil
                        </Button>
                        <span className="file-name">{file ? file.name : "Ingen fil valgt"}</span>
                      </div>
                      <code>
                        {Object.keys(res).length > 0
                          ? Object.keys(res).map((key) => (
                              <p className="output-item" key={key}>
                                <span>{key}: </span>
                                <span>
                                  {typeof res[key] === "object" ? "object" : res[key]}
                                </span>
                              </p>
                            ))
                          : null}
                      </code>
                    </div>

            
                    <Form.Group controlId="formBasicCondition" className='mb-3'>
  <Form.Label className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
    Stand
    <span onClick={handleShowModal} style={{ cursor: 'pointer', marginLeft: '5px', fontSize: '0.8em', verticalAlign: 'middle' }}>
      (?)
    </span>
  </Form.Label>
  <Form.Control
    as="select"
    className="mt-2"
    value={condition}
    onChange={(e) => {
      setCondition(e.target.value);
      e.target.setCustomValidity(''); // Clear any previous custom validity message
    }}
    onInvalid={(e) => e.target.setCustomValidity('Du skal specificere bogens stand')} // Set custom message
  >
    <option value="">Vælg stand</option>
    <option value="Perfekt stand">Perfekt stand</option>
    <option value="God stand">God stand</option>
    <option value="Slidt stand">Slidt stand</option>
  </Form.Control>
</Form.Group>
                    <Form.Group controlId="formBasicReleaseYear" className='mb-3'>
                      <Form.Label>Udgivelsesår</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast året for bogens udgivelse"
                        value={releaseYear}
                        onChange={(e) => setReleaseYear(e.target.value)}
                      />
                    </Form.Group>
              
                    <Form.Group controlId="formBasicDescription" className='mb-3'>
                      <Form.Label style={{ display: 'flex', alignItems: 'center' }}>
                        Beskrivelse
                        {/* <span style={{ cursor: 'pointer', marginLeft: '5px', fontSize: '0.8em', verticalAlign: 'middle' }}>
                          (Valgfrit)
                        </span> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Indtast beskrivelse eller kommentar om bogen - evt. forlag, udgave, afhentningsmuligheder eller andet"
                        rows={6}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                 
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicISBN" className='mb-3'>
                      <Form.Label>ISBN
                      {/* <span style={{ cursor: 'pointer', marginLeft: '5px', fontSize: '0.8em', verticalAlign: 'middle' }}>
                          (Valgfrit)
                        </span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast ISBN-nummer"
                        value={isbn}
                        onChange={(e) => setIsbn(e.target.value)}
                      />
                    </Form.Group>
         
          
                    <Button variant="primary" type="submit" className='mt-3 search-form-frontpage-button'>
                      Opret annonce
                    </Button>
                  </Form>
                </div>
              </>
            ) : (
              <div>
                <h2 className='mt-5'>Din annonce er blevet oprettet.</h2>
                <p className='lead'>Du føres til annoncen om få sekunder.</p>
              </div>
            )
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Beskrivelse af bogstand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Perfekt stand:</strong> Bogen er som ny uden nogen tegn på brug.</p>
          <p><strong>God stand:</strong> Bogen har været brugt, men er stadig i god stand uden større skader.</p>
          <p><strong>Slidt stand:</strong> Bogen viser tydelige tegn på brug og kan have skader.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Luk
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default CreateAdPage;