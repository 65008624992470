import React, { useState, useEffect } from 'react';
import { Container, Form, Button, InputGroup, Modal } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function EditProfile() {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [deleteError, setDeleteError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/currentuser", {
          withCredentials: true,
        });

        if (response.data.status) {
          setUser(response.data);
          setEmail(response.data.email);
          setName(response.data.name);
          setZipcode(response.data.zipcode);
          setPhone(response.data.phone);
        } else {
          navigate('/profil/login');
        }
      } catch (error) {
        console.error(error);
        navigate('/profil/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  const validatePassword = (password) => {
    setPasswordRepeat(password);

    // Define the criteria for a strong password
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Check if the password meets the criteria
    if (password.length < minLength) {
      setPasswordError('Koden skal være over 8 karakterer.');
    } else if (!hasUpperCase) {
      setPasswordError('Koden skal indeholde mindst 1 stort bogstav.');
    } else if (!hasLowerCase) {
      setPasswordError('Koden skal indeholde mindst 1 lille bogstav.');
    } else if (!hasNumber) {
      setPasswordError('Koden skal indeholde mindst 1 tal.');
    } else if (!hasSpecialChar) {
      setPasswordError('Koden skal indeholde mindst 1 special karakter (f.eks. et af følgende: ",#,%,!,/").');
    } else {
      setPasswordError('');
    }

    // Check if the passwords match
    if (password !== passwordRepeat) {
      setRepeatPasswordError('Koderne er ikke ens');
      return false;
    } else {
      setRepeatPasswordError('');
    }

    return true;
  };

  const validateFields = () => {
    const errors = {};
    if (!email) errors.email = 'Email er påkrævet.';
    if (!name) errors.name = 'Navn er påkrævet.';
    if (!zipcode) errors.zipcode = 'Postnummer er påkrævet.';
    if (!phone) errors.phone = 'Telefonnummer er påkrævet.';
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields() || (password && !validatePassword(password, passwordRepeat))) {
      return;
    }

    try {
      const response = await axios.put(`/api/updateuser/${user.id}`, {
        email,
        password,
        zipcode,
        name,
        phone
      });
      if (response.data.success) {
        setSubmitSuccess(true);
        setTimeout(() => {
          window.location.href = '/profil/rediger';
        }, 2000); 
      } else {
        setErrorMessage('Opdatering mislykkedes.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Der opstod en fejl under opdateringen.');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.post(`/api/validatepassword`, {
        email,
        password: deletePassword
      });

      if (response.data.success) {
        await axios.delete(`/api/deleteuser/${user.id}`, {
          data: { email, password: deletePassword }
        });
        alert('Din profil er blevet slettet.');
        setTimeout(() => {
          window.location.href = '/profil/opret';
        }, 1500); 
      } else {
        setDeleteError('Forkert kodeord.');
      }
    } catch (error) {
      console.error(error);
      setDeleteError('Der opstod en fejl under sletningen.');
    }
  };

  const getRequirementStyle = (requirementMet) => {
    return { color: requirementMet ? 'green' : 'black' };
  };

  const minLength = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    <Container>
      <div className='row mt-5'>
        <div className='col-4'></div>
        <div className='col-4'>
          {!submitSuccess ? (
            <>
              <h1>Rediger profil</h1>
              <p>Opdater dine profiloplysninger.</p>
            </>
          ) : (
            <></>
          )}

          {!submitSuccess ? (
            <div className='form-searchpage-background mb-5'>
              <Form onSubmit={handleSubmit} className='mt-2'>
                <Form.Group controlId="formBasicEmail" className='mb-3'>
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="email"
                      placeholder="Indtast email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isInvalid={!!fieldErrors.email}
                      readOnly
                    />
                 
                    <Form.Control.Feedback type="invalid">
                      {fieldErrors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicPhone" className='mb-3'>
                  <Form.Label>Telefonnummer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Dit telefonnummer"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    isInvalid={!!fieldErrors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicName" className='mb-3'>
                  <Form.Label>Navn</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Indtast dit navn"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isInvalid={!!fieldErrors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className='mb-3'>
                  <Form.Label>Kode</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Kode"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!fieldErrors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <ul style={{ fontSize: 'small' }}>
                  <li style={getRequirementStyle(minLength)}>Koden skal have mindst 8 karakterer</li>
                  <li style={getRequirementStyle(hasUpperCase)}>Koden skal indeholde mindst 1 stort bogstav</li>
                  <li style={getRequirementStyle(hasLowerCase)}>Koden skal indeholde mindst 1 lille bogstav</li>
                  <li style={getRequirementStyle(hasNumber)}>Koden skal indeholde mindst 1 tal</li>
                  <li style={getRequirementStyle(hasSpecialChar)}>Koden skal indeholde mindst 1 special karakter (f.eks. et af følgende: ",#,%,!,/")</li>
                </ul>
                {passwordError && <p className="text-danger">{passwordError}</p>}
                <Form.Group controlId="formBasicPasswordRepeat" className='mb-3'>
                  <Form.Label>Gentag kode</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Gentag ovenstående kode"
                    value={passwordRepeat}
                    onChange={(e) => setPasswordRepeat(e.target.value)}
                    isInvalid={!!fieldErrors.passwordRepeat}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.passwordRepeat}
                  </Form.Control.Feedback>
                </Form.Group>
                {repeatPasswordError && <p className="text-danger">{repeatPasswordError}</p>}
                <Form.Group controlId="formBasicZipcode" className='mb-3'>
                  <Form.Label>Postnummer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Dit postnummer"
                    value={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                    isInvalid={!!fieldErrors.zipcode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.zipcode}
                  </Form.Control.Feedback>
                </Form.Group>

                {errorMessage && <p className="text-danger">{errorMessage}</p>}

                <Button variant="primary" type="submit" className='mt-3 search-form-frontpage-button'>
                  Opdater profil
                </Button>
              </Form>
            </div>
          ) : (
            <>
              <h2 className='mt-5'>Din profil er blevet opdateret. </h2>
            </>
          )}
          <p><a  className="dark-link" onClick={() => setShowModal(true)}>slet profil?</a></p>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Bekræft sletning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formDeletePassword" className='mb-3'>
            <Form.Label>Indtast kodeord for at bekræfte</Form.Label>
            <Form.Control
              type="password"
              placeholder="Kodeord"
              value={deletePassword}
              onChange={(e) => setDeletePassword(e.target.value)}
              isInvalid={!!deleteError}
            />
            <Form.Control.Feedback type="invalid">
              {deleteError}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuller
          </Button>
          <Button variant="danger" onClick={handleDeleteAccount}>
            Slet profil
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditProfile;