import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Form, Button} from "react-bootstrap";
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(
        '/api/login',
        { email, password },
        { withCredentials: true }
      );
  
      if (response.data.success) {
        setSubmitSuccess(true);
        setErrorMessage('');
  
        const queryParams = new URLSearchParams(location.search);
        const shortAdId = queryParams.get('redirected-from-ad');
      const shortSellerId = queryParams.get('redirected-from-seller');
  
        if (shortAdId) {
          // Redirect back to the ad page
          setTimeout(() => {
            window.location.href = `/bøger/annonce/${shortAdId}`;
          }, 2000);
        }else if (shortSellerId) {
          // Redirect back to the seller's profile page
          setTimeout(() => {
            window.location.href = `/profil/${shortSellerId}`;
          }, 2000);
        }  else {
          // Default redirect to homepage
          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
        }
      } else {
        // Display error message from the backend
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        error.response?.data?.message || 'Der opstod en fejl under login'
      );
    }
  };

  return (
    <Container>
      <div className='row mt-5'>
      <div className='col-lg-4'>
        </div>
        <div className='col-lg-4'>
        <h1>Login på din bruger</h1>
     


        {!submitSuccess ? (     
          <>
             <p className='lead'>Indtast din email og kode for at logge ind på din bruger</p>
         <div className='form-searchpage-background'>
         <Form onSubmit={handleSubmit} className='mt-2 mb-3'>
           <Form.Group controlId="formBasicEmail" className='mb-3'>
             <Form.Label>Email</Form.Label>
             <Form.Control
               type="email"
               placeholder="Indtast email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
             />
           </Form.Group>

        <Form.Group controlId="formBasicPassword" className='mb-3'>
                  <Form.Label>Kode</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Kode"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
  
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                      
        <Button variant="primary" type="submit" className='mt-2 mb-3 search-form-frontpage-button'>
          Login
        </Button>
        
      </Form>
      <p>Er du ikke tilmeldt endnu? <a href='/profil/opret' className='mt-5 dark-link'>Opret en bruger her</a></p>
        </div></>) : (<><h4 className='mt-5'>Du er nu logget ind. </h4></>)}
        </div>
      </div>
    </Container>
  );
} 

export default LoginPage;