import React, { useState, useEffect } from "react";
import { Navbar, Nav, Form, Button, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logocropped.png';

function NavBar() {
  const [user, setUser] = useState(null);
  const [searchType, setSearchType] = useState("Forfatter");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/currentuser", {
          withCredentials: true,
        });

        if (response.data.status) {
          console.log("User Data:", response.data); // Check what data is returned
          setUser(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const queryTypeMap = {
      "Forfatter": "author",
      "Titel": "title",
      "ISBN": "isbn"
    };
    const queryString = `${queryTypeMap[searchType]}=${encodeURIComponent(searchQuery)}`;
    navigate(`/bøger?${queryString}`);
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout', {}, {
        withCredentials: true,
      });
      setUser(null);
      navigate('/profil/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const getPlaceholderText = () => {
    if (searchType === "Forfatter") {
      return "Søg efter forfatter";
    } else if (searchType === "Titel") {
      return "Søg efter titel";
    }
    return `Søg efter ${searchType.toLowerCase()}`;
  };

  return (
    <>
      <Navbar expand="xl" className="mt-1">
        <Navbar.Brand href="/" className="">
          <img
            src={logo}
            alt="Bogbiks Logo"
            width="180" // Adjust the width as needed
            height="auto" // Maintain aspect ratio
            className="ms-3 d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Form className="search-form" onSubmit={handleSearch}>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder={getPlaceholderText()}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="custom-input black-text"
                />
               <DropdownButton
                  as={InputGroup.Append}
                  variant="none"
                  title={searchType}
                  id="input-group-dropdown-2"
                  // className="search-dropdown-navbar-button"
                  bsPrefix="search-dropdown-navbar-button margin-small-screen" 
                  align="end"
                >
                  <Dropdown.Item className="dropdown-colors-navbar" onClick={() => handleSearchTypeChange("Forfatter")}>Forfatter</Dropdown.Item>
                  <Dropdown.Item className="dropdown-colors-navbar" onClick={() => handleSearchTypeChange("Titel")}>Titel</Dropdown.Item>
                  <Dropdown.Item className="dropdown-colors-navbar" onClick={() => handleSearchTypeChange("ISBN")}>ISBN</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </Form>
     
          </Nav>

          {user ? (
            <>
              <Nav.Link href="/bøger/opret-annonce">
                <Button type="submit" className="me-3 search-form-frontpage-button zero-nine-em-font-size margin-small-screen">Opret annonce</Button>
              </Nav.Link>
              <Nav className="ml-auto">
              <DropdownButton
                  variant="none"
                  title="Din profil"
                  id="profile-dropdown"
                  className=" margin-small-screen"
                  bsPrefix="profile-dropdown-navbar-button" 
                  align="end"
                >
                  <Dropdown.Item className="dropdown-colors-navbar" href="/profil/annoncer">Dine annoncer</Dropdown.Item>
                  <Dropdown.Item className="dropdown-colors-navbar" href="/profil/rediger">Rediger profil</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item className="dropdown-colors-navbar" onClick={handleLogout}>Logud</Dropdown.Item>
                </DropdownButton>
              </Nav>
            </>
          ) : (
            <Nav className="ml-auto">
              <Nav.Link href="/profil/login" className="nav-profile-buttons ps-3 pe-3 responsive-width-navbuttons">Log ind</Nav.Link>
              <Nav.Link href="/profil/opret" className="nav-profile-buttons ps-3 pe-3 responsive-width-navbuttons">Opret profil</Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavBar;