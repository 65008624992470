import React, { useState } from 'react';
import { Container, Form, Button, Carousel} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

function FrontPage() {
    const navigate = useNavigate();
    const [author, setAuthor] = useState('');
    const [title, setTitle] = useState('');
    const [isbn, setIsbn] = useState('');

    const [zipcode, setZipcode] = useState('');
    const [pickupDistance, setPickupDistance] = useState(null);
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      const queryParams = {
        author,
        title,
        isbn,
        zipcode,
        pickupDistance,

      };

        if (zipcode) {
          queryParams.pickupDistance = pickupDistance || 1;
        }
      
      const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== '' && queryParams[key] !== false && queryParams[key] !== null)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    navigate(`/bøger?${queryString}`);
  };

  return (
    <Container>
    <div className="row ">
      <div className="col-lg-4 mt-3 mb-5">
        <Form onSubmit={handleFormSubmit} className="p-3 form-frontpage-background">
          <p className="align-left text-meta-form">
          Køb bøger fra private sælgere og antikvariater
          </p>
          <h5 className="align-left mb-4">
            Søg efter brugte bøger til afhentning i dit lokalområde
          </h5>
          <Form.Group controlId="" className="mt-3 mb-3">
              <Form.Label className="align-left low-margin-bottom"><b>Forfatter</b></Form.Label>
              <Form.Control
                type="text"
                placeholder="Søg efter forfatter"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="" className="mt-3 mb-3">
              <Form.Label className="align-left low-margin-bottom"><b>Titel</b></Form.Label>
              <Form.Control
                type="text"
                placeholder="Søg efter titel"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="" className="mt-3 mb-3">
              <Form.Label className='align-left low-margin-bottom'><b>ISBN</b></Form.Label>
              <Form.Control
                type="text"
                placeholder="Søg efter ISBN"
                value={isbn}
                onChange={(e) => setIsbn(e.target.value)}
              />
            </Form.Group>
     
              <div className="local">
                <Form.Group controlId="zipcode" className="mb-3">
                  <Form.Label className="align-left low-margin-bottom"><b>Postnummer og afhentningsafstand</b></Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      type="text"
                      placeholder="Dit postnummer"
                      value={zipcode}
                      onChange={(e) => setZipcode(e.target.value)}
                      className="me-2"
                    />
                    <Form.Select
                      value={pickupDistance}
                      onChange={(e) => setPickupDistance(e.target.value)}
                    >
                      <option value={1}>1 km</option>
                      <option value={5}>5 km</option>
                      <option value={10}>10 km</option>
                      <option value={20}>20 km</option>
                      <option value={50}>50 km</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </div>
        

          <Button type="submit" className='mt-3 search-form-frontpage-button'>Søg efter bog</Button>
        </Form>
      </div>
      
      <div className="col-lg-8 mt-3 mb-5">
      <div className='relativ-div-frontpage'>
       <div className="banner-frontpage-background min-height-40vh">
           <div className='row'>
              <div className="content frontpage-banner-textspot">

              <h2 className="mb-3 pt-4 font-weight-700">
              Køb og sælg brugte bøger
              </h2>
        
              <p className="lead text-banner-frontpage stick-bottom">
                <b>Bogbiks.dk er en online markedsplads for brugte bøger. Her kan du finde bøger til salg af private sælgere og antikvariater - <a href="/profil/opret" className='light-blue-link'>og sælge dine egne</a></b>
              </p>
          {/* <p className='light-blue-link'>Læs mere om bogbiks her</p> */}
              </div>
      
              </div>
        
        </div>
        </div>

        </div>
        
    

  
        {/* <div className="row mt-5 ps-5 pe-5 mb-5">
          <h4 className='mb-4'>Populære bøger</h4>
          <div className="col-2 pop-books-color me-2 p-2">
            <img src="https://pictures.abebooks.com/isbn/9781478006510-us-300._FMwebp_.jpg" alt="bog" width={100} height={100} />
            <p>Necropolitics <br></br>af Achille Mbembe</p>
           
          </div>
    
          <div className="col-2 pop-books-color me-2 p-2">
            <img src="https://pictures.abebooks.com/isbn/9781478006510-us-300._FMwebp_.jpg" alt="bog" width={100} height={100} />
            <p>Necropolitics <br></br>af Achille Mbembe</p>
           
          </div>
          <div className="col-2 pop-books-color me-2 p-2">
            <img src="https://pictures.abebooks.com/isbn/9781478006510-us-300._FMwebp_.jpg" alt="bog" width={100} height={100} />
            <p>Necropolitics <br></br>af Achille Mbembe</p>
           
          </div>
          <div className="col-2 pop-books-color p-2">
            <img src="https://pictures.abebooks.com/isbn/9781478006510-us-300._FMwebp_.jpg" alt="bog" width={100} height={100} />
            <p>Necropolitics <br></br>af Achilless sdsds dsdsd dsdsdsdsds sdsdsd sds dMbembe</p>
           
          </div>
          <div className="col-2 pop-books-color p-2">
            <img src="https://pictures.abebooks.com/isbn/9781478006510-us-300._FMwebp_.jpg" alt="bog" width={100} height={100} />
            <p>Necropolitics <br></br>af Achilless sdsds dsdsd dsdsdsdsds sdsdsd sds dMbembe</p>
           
          </div>
        </div>  */}

    </div>

  </Container>
  );
}

export default FrontPage;
