import React, { useState } from 'react';
import { Container, Form, Button } from "react-bootstrap";
import axios from 'axios';

function SignupPage() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});

  const validatePassword = (password, passwordRepeat) => {
    // Define the criteria for a strong password
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Check if the password meets the criteria
    if (password.length < minLength) {
      setPasswordError('Koden skal være over 8 karakterer.');
      return false;
    } else if (!hasUpperCase) {
      setPasswordError('Koden skal indeholde mindst 1 stort bogstav.');
      return false;
    } else if (!hasLowerCase) {
      setPasswordError('Koden skal indeholde mindst 1 lille bogstav.');
      return false;
    } else if (!hasNumber) {
      setPasswordError('Koden skal indeholde mindst 1 tal.');
      return false;
    } else if (!hasSpecialChar) {
      setPasswordError('Koden skal indeholde mindst 1 special karakter (f.eks. et af følgende: ",#,%,!,/").');
      return false;
    } else {
      setPasswordError('');
    }

    // Check if the passwords match
    if (password !== passwordRepeat) {
      setRepeatPasswordError('Koderne er ikke ens');
      return false;
    } else {
      setRepeatPasswordError('');
    }

    return true;
  };

  const validateFields = () => {
    const errors = {};
    if (!email) errors.email = 'Email er påkrævet.';
    if (!name) errors.name = 'Navn er påkrævet.';
    if (!password) errors.password = 'Kode er påkrævet.';
    if (!passwordRepeat) errors.passwordRepeat = 'Gentag kode er påkrævet.';
    if (!zipcode) errors.zipcode = 'Postnummer er påkrævet.';
    if (!phone) errors.phone = 'Telefonnummer er påkrævet.';
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields() || !validatePassword(password, passwordRepeat)) {
      return;
    }

    try {
      const response = await axios.post('/api/signup', {
        email,
        password,
        zipcode,
        name,
        phone
      });
      if (response.data.success) {
        setSubmitSuccess(true);
      } else {
        setErrorMessage('Registrering mislykkedes.');
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setErrorMessage('Emailen er allerede registreret.');
      } else {
        setErrorMessage('Der opstod en fejl under registreringen.');
      }
    }
  };

  const getRequirementStyle = (requirementMet) => {
    return { color: requirementMet ? 'green' : 'black' };
  };

  const minLength = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    <Container>
      <div className='row mt-5'>
        <div className='col-lg-4'></div>
        <div className='col-lg-4'>
          {!submitSuccess ? (
            <>
              <h1>Opret profil</h1>
              <p>Opret en profil og kom igang med at købe og sælge bøger på bogbiks.dk</p>
            </>
          ) : (
            <></>
          )}

          {!submitSuccess ? (
            <div className='form-searchpage-background mb-5'>
              <Form onSubmit={handleSubmit} className='mt-2'>
                <Form.Group controlId="formBasicEmail" className='mb-3'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Indtast email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!fieldErrors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPhone" className='mb-3'>
                  <Form.Label>Telefonnummer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Dit telefonnummer"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    isInvalid={!!fieldErrors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicName" className='mb-3'>
                  <Form.Label>Navn</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Indtast dit navn"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isInvalid={!!fieldErrors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className='mb-3'>
                  <Form.Label>Kode</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Kode"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!fieldErrors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <ul style={{ fontSize: 'small' }}>
                  <li style={getRequirementStyle(minLength)}>Koden skal have mindst 8 karakterer</li>
                  <li style={getRequirementStyle(hasUpperCase)}>Koden skal indeholde mindst 1 stort bogstav</li>
                  <li style={getRequirementStyle(hasLowerCase)}>Koden skal indeholde mindst 1 lille bogstav</li>
                  <li style={getRequirementStyle(hasNumber)}>Koden skal indeholde mindst 1 tal</li>
                  <li style={getRequirementStyle(hasSpecialChar)}>Koden skal indeholde mindst 1 special karakter (f.eks. et af følgende: ",#,%,!,/")</li>
                </ul>
                {passwordError && <p className="text-danger">{passwordError}</p>}
                <Form.Group controlId="formBasicPasswordRepeat" className='mb-3'>
                  <Form.Label>Gentag kode</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Gentag ovenstående kode"
                    value={passwordRepeat}
                    onChange={(e) => setPasswordRepeat(e.target.value)}
                    isInvalid={!!fieldErrors.passwordRepeat}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.passwordRepeat}
                  </Form.Control.Feedback>
                </Form.Group>
                {repeatPasswordError && <p className="text-danger">{repeatPasswordError}</p>}
                <Form.Group controlId="formBasicZipcode" className='mb-3'>
                  <Form.Label>Postnummer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Dit postnummer"
                    value={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                    isInvalid={!!fieldErrors.zipcode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.zipcode}
                  </Form.Control.Feedback>
                </Form.Group>

                {errorMessage && <p className="text-danger">{errorMessage}</p>}

                <Button variant="primary" type="submit" className='mt-3 search-form-frontpage-button'>
                  Opret profil
                </Button>
              </Form>
            </div>
          ) : (
            <>
              <h2 className='mt-5'>Din profil er blevet oprettet. </h2>
              <p className='lead'>Før at den er aktiv, skal du bekræfte din email ved at trykke på det aktiveringslink, vi netop har sendt til dig.</p>
            </>
          )}
        </div>
      </div>
    </Container>
  );
}

export default SignupPage;