import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import userIcon from '../../images/user-3296.svg'; // Import the SVG file as a URL
import pinIcon from '../../images/pin-48.svg'; // Import the SVG file as a URL
import listIcon from '../../images/list-6240.svg'; // Import the SVG file as a URL
import calenderIcon from '../../images/calendar-11015.svg'; // Import the SVG file as a URL

function AdPage() {
  const { shortAdId } = useParams();
  const location = useLocation();
  const [ad, setAd] = useState(null);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const response = await fetch(`/api/books/ads/${shortAdId}`);
        const data = await response.json();
        setAd(data);
      } catch (error) {
        console.error('Error fetching ad:', error);
      }
    };

    fetchAd();
  }, [shortAdId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/currentuser", {
          withCredentials: true,
        });

        if (response.data.status) {

          setUser(response.data);
        } else {
        }
      } catch (error) {

      }
    };
    fetchUserData();

  }, []);

  const queryParams = new URLSearchParams(location.search);
  const redirectedFromCreatePage = queryParams.get('redirected') === 'create-page';

  if (!ad) {
    return (
      <Container className="adpage-container">
        <Row className="mt-5">
          <Col lg={4}></Col>
          <Col lg={8}>
            <h2>Annoncen kan ikke findes</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="adpage-container">
      <Row className="mt-5 justify-content-center">
        <Col lg={2}>
          <Image src={ad.adImg} alt="bog" fluid className="adpage-image" />
        </Col>
        <Col lg={5}>
          <h2 className="adpage-title">{ad.title}</h2>
          <h5 className="mb-3 adpage-author">af {ad.author}</h5>
          <h5>Pris: {ad.price} kr.</h5>

          <br></br>
          {ad.description && <p><strong>Beskrivelse:</strong> {ad.description}</p>}
          <p><img src={calenderIcon} width="26" height="26" alt="Udgivelsesår ikon" className="bi bi-person me-2" /> <strong>Udgivelsesår:</strong> {ad.releaseYear} {ad.isbn && <><strong>ISBN:</strong> {ad.isbn}</>}</p>
          <p><img src={listIcon} width="26" height="26" alt="Stand ikon" className="bi bi-person me-2" /> <strong>Stand:</strong> {ad.condition}</p>
          <p><img src={pinIcon} width="26" height="26" alt="Postnummer ikon" className="bi bi-person me-2" /> <strong>Postnummer:</strong> {ad.zipcode}</p>


          {/* {!redirectedFromCreatePage && (
            <Button variant="primary" className="adpage-back-button" onClick={() => {
              window.history.back();
              window.scrollTo(0, 0);
            }}>
              Tilbage
            </Button>
          )} */}
        </Col>

        <Col lg={4} className='adpage-seller-info p-3'>
          <h4 className="mb-3">Sælgers kontaktinfo</h4>
          <p><img src={userIcon} width="26" height="26" alt="User Icon" className="bi bi-person me-2" /> <a href={`/profil/${ad.sellerShortId}`} className='light-blue-link'>{ad.sellerName}</a> </p>

         

          {user ? (
            <>
              <p className="adpage mb-2">Telefonnummer: {ad.sellerPhone}</p>
              <p className="adpage mb-2">Email: {ad.sellerMail}</p>
              <p className="adpage mb-2">Postnummer: {ad.zipcode}</p>
            </>
          ) : (
            <>
              <p>Du skal være logget ind for at kontakte sælger</p>

              <div className='row mt-3 d-flex justify-content-center align-items-center'>
                <Button
                  className='signin-adpage pe-5 ps-5'
                  onClick={() => navigate(`/profil/login?redirected-from-ad=${shortAdId}`)}
                >
                  Log ind
                </Button>
              </div>


            </>
          )}

        </Col>
        <Col lg={1}>


        </Col>
      </Row>
    </Container>
  );
}

export default AdPage;