import React from "react";
import { Navbar, Nav, NavDropdown, Container, Form } from "react-bootstrap";

function Footer() {
  return (
    <Container fluid id="footer">
    <Container className="pt-4">
    <div className="footer footer-background-color">
    
            <p className="mt-3">Bogbiks er en online markedsplads for bøger</p>
      <br></br>
        </div>
        </Container>
        </Container>
  );
}

export default Footer;
