import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

function VerifyEmailPage() {
  const [searchParams] = useSearchParams(); // Extract query parameters
  const email = searchParams.get('email');
  const activationString = searchParams.get('activationString');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [expired, setExpired] = useState(false); // Track if the link is expired

  useEffect(() => {
    const verifyEmail = async () => {
      if (!email || !activationString) {
        setMessage('Invalid or missing parameters.');
        setSuccess(false);
        return;
      }

      try {
        const response = await axios.get(`/api/verify-email`, {
          params: { email, activationString },
        });
        setMessage(response.data.message); // Display success message
        setSuccess(true);
        setExpired(false); // Reset expired state if successful
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'An error occurred during verification.';
        setMessage(errorMessage);
        setSuccess(false);

        // Check if the error response includes "expired: true"
        if (error.response?.data?.expired) {
          setExpired(true);
        }
      }
    };

    verifyEmail();
  }, [email, activationString]);

  const handleResendLink = async () => {
    try {
      const response = await axios.post(`/api/resend-verification`, { email });
      setMessage(response.data.message); // Display success message for resend
      setExpired(false); // Reset expired state after resending
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred while resending the link.');
    }
  };

  return (
    <div className="container mt-5">
      <h1>Email Verificering</h1>
      <p className={success ? 'text-success' : 'text-danger'}>{message}</p>
      {success && <p>Du kan nu <a href="/profil/login" className='dark-link'>logge ind</a>.</p>}
      {expired && (
        <div>
          <p className="text-warning">Dit aktiveringslink er udløbet. Klik på knappen nedenfor for at få et nyt link.</p>
          <button className="btn btn-primary" onClick={handleResendLink}>
            Send nyt aktiveringslink
          </button>
        </div>
      )}
    </div>
  );
}

export default VerifyEmailPage;