import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate, Link } from 'react-router-dom';


function SearchPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  // State for current search parameters
  const [currentSearch, setCurrentSearch] = useState({
    author: '',
    title: '',
    isbn: '',
    zipcode: '',
    pickupDistance: null,
  });

  // State for form inputs
  const [formInputs, setFormInputs] = useState({
    author: '',
    title: '',
    isbn: '',
    zipcode: '',
    pickupDistance: null,
  });

  const [results, setResults] = useState([]);
  const [zipcodesInRange, setZipcodesInRange] = useState([]);

  useEffect(() => {
    const newSearch = {
      author: queryParams.get('author') || '',
      title: queryParams.get('title') || '',
      isbn: queryParams.get('isbn') || '',
      zipcode: queryParams.get('zipcode') || '',
      pickupDistance: queryParams.get('pickupDistance') || null,
    };
    setCurrentSearch(newSearch);
    setFormInputs(newSearch);
  }, [location.search]);

  useEffect(() => {
    const fetchResults = async () => {
      const filteredSearch = Object.fromEntries(
        Object.entries(currentSearch).filter(
          ([key, value]) => value !== '' && value !== false && value !== null
        )
      );

      // Check if at least one of title, author, or isbn is present
      if (!filteredSearch.title && !filteredSearch.author && !filteredSearch.isbn) {
        setResults([]);
        return;
      }

      const query = new URLSearchParams(filteredSearch).toString();
      console.log(query);
      const response = await fetch(`/api/books?${query}`);
      const data = await response.json();
      console.log(data);
      setResults(data.results || []); // Ensure results is always an array
      if (data.zipcodesInRange) {
        setZipcodesInRange(data.zipcodesInRange);
      }
    };

    fetchResults();
  }, [currentSearch]);

  const handleSearch = async (e) => {
    e.preventDefault();
    const query = new URLSearchParams();

    if (formInputs.author) query.set('author', formInputs.author);
    if (formInputs.title) query.set('title', formInputs.title);
    if (formInputs.isbn) query.set('isbn', formInputs.isbn);

    if (formInputs.zipcode) {
      query.set('zipcode', formInputs.zipcode);
      query.set('pickupDistance', formInputs.pickupDistance || 1); // Use selected value or default to 1 km
    } else {
      query.set('pickupDistance', 1000); // Default to 1000 km if no zipcode is provided
    }

    for (const [key, value] of query.entries()) {
      if (value === '' || value === 'false' || value === '1000') {
        query.delete(key);
      }
    }
    const queryString = query.toString().replace(/\+/g, '%20');
    navigate(`/bøger?${queryString}`);
    setCurrentSearch(formInputs);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormInputs((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <Container className='pb-5'>
      <div className='row'>
        <h1 className='h1-searchpage'>Søgeresultater</h1>
        {currentSearch.title || currentSearch.author || currentSearch.isbn ? (
          results.length >= 0 && <p><b>{results.length} resultater</b></p>
        ) : (
          <p><b>Du skal søge efter forfatter, bogtitel eller ISBN</b></p>
        )}
      </div>
      <div className='row border-bottom mb-3'></div>
      <div className='row'>
        <div className='col-lg-3'>
          <Form className="p-3 mb-5 form-searchpage-background" onSubmit={handleSearch}>
            <Form.Group controlId="" className=" mb-3">
              <Form.Label className="align-left"><b>Forfatter</b></Form.Label>
              <Form.Control
                type="text"
                placeholder="Søg efter forfatter"
                name="author"
                value={formInputs.author}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="" className="mt-3 mb-3">
              <Form.Label className="align-left"><b>Titel</b></Form.Label>
              <Form.Control
                type="text"
                placeholder="Søg efter titel"
                name="title"
                value={formInputs.title}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="" className="mt-3 mb-3">
              <Form.Label><b>ISBN</b></Form.Label>
              <Form.Control
                type="text"
                placeholder="Søg efter ISBN"
                name="isbn"
                value={formInputs.isbn}
                onChange={handleInputChange}
              />
            </Form.Group>

            <div className="local">
              <Form.Group controlId="zipcode" className="mb-3">
                <Form.Label className="align-left"><b>Postnummer og afstand</b></Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    placeholder="Postnummer"
                    name="zipcode"
                    value={formInputs.zipcode}
                    onChange={handleInputChange}
                    className="me-2"
                  />
                  <Form.Select
                    name="pickupDistance"
                    value={formInputs.pickupDistance}
                    onChange={handleInputChange}
                  >
                    <option value={1}>1 km</option>
                    <option value={5}>5 km</option>
                    <option value={10}>10 km</option>
                    <option value={20}>20 km</option>
                    <option value={50}>50 km</option>
                    <option value={100}>100 km</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>

            <Button type="submit" className='mt-3 search-form-frontpage-button'>Opdater søgning</Button>
          </Form>
        </div>
        <div className='col-lg-9'>
          {results.map((ad) => {
            // Filter ads based on the current search criteria
            if (currentSearch.zipcode && !zipcodesInRange.includes(ad.zipcode)) {
              return null;
            }

            return (
             
              <div key={ad._id} >
              <div className='row pb-3 aditem-searchpage buttom-border mb-3 relativ-div'>
                <div className='col-lg-3'>
                  <img src={ad.adImg} className="adimg-searchpage me-1" alt="bog" />
                    </div>  
                <div className='col-lg-5'>
                  <h5>{ad.title}</h5>
                  <p>af <Link className="dark-link" to={`/bøger?author=${ad.author}`}>{ad.author}</Link></p>
                  {ad.price && <p><strong>Pris:</strong> {ad.price} kr.</p>}
                  {ad.condition && <p><strong>Stand:</strong> {ad.condition}</p>}
                  {ad.zipcode && <p><strong>Postnummer:</strong> {ad.zipcode}</p>}
                </div>
                <div className='col-lg-4'>
                  <a href={`/bøger/annonce/${ad.shortAdId}`} className='signature-blue-link-search-ad'>
                  Se annonce
                  </a>
                  {/* <Button
                    className='salgsannoncer-button'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(`/bøger/annonce/${ad.shortAdId}`);
                    }}
                  >
                    Se annonce
                  </Button> */}
                </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default SearchPage;