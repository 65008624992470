import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';

function ProfileAdsDisplay() {
  const { shortUserId } = useParams(); // Get the shortUserId from the URL
  const navigate = useNavigate();
  const [user, setUser] = useState(null); // State for logged-in user
  const [profile, setProfile] = useState(null); // State for profile details
  const [ads, setAds] = useState([]); // State for ads
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the logged-in user
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axios.get("/api/currentuser", {
          withCredentials: true,
        });

        if (response.data.status) {
          setUser(response.data); // Set logged-in user details
        } else {
          setUser(null); // No logged-in user
        }
      } catch (error) {
        console.error("Error fetching current user:", error);
        setUser(null); // No logged-in user
      }
    };

    fetchCurrentUser();
  }, []);

  // Fetch the profile and ads for the given shortUserId
  useEffect(() => {
    const fetchProfileAndAds = async () => {
      try {
        const response = await axios.get(`/api/books/profile/${shortUserId}`); // Use shortUserId in the API call
        if (response.data.success) {
          setProfile(response.data.user); // Set profile details
          setAds(response.data.ads); // Set ads
        } else {
          setError('Could not fetch profile or ads for this user.');
        }
      } catch (err) {
        console.error("Error fetching profile and ads:", err);
        setError('An error occurred while fetching profile or ads.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileAndAds();
  }, [shortUserId]); // Update dependency to shortUserId

  if (loading) {
    return <p>Indlæser annoncer...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container className="mt-5 mb-5">
      {/* Display profile details */}
      <div className="row mb-4">
        <div className="col-lg-4">
      <h1>{profile.name}s annoncer</h1>
      {user ? (
    <>
         
          <p>
            <strong>Email:</strong> {profile.email} <br />
            <strong>Telefon:</strong> {profile.phone} <br />
            <strong>Postnummer:</strong> {profile.zipcode}
          </p>
          </>
      ) : (
        <>
          
          <p>Du skal være logget ind for at kontakte sælger</p>
          <a href={`/profil/login?redirected-from-seller=${shortUserId}`} className='signature-blue-link-profile-page'>Log ind her</a>

            {/* <Button
              className="signin-profilepage pe-2 ps-2 mt-1"
              onClick={() => navigate(`/profil/login?redirected-from-seller=${shortUserId}`)}
            >
              Log ind
            </Button> */}
         
        </>
      )}
      </div>
          </div>

      {ads.length === 0 ? (
        <p>Denne bruger har ingen bøger til salg.</p>
      ) : (
        <Row className='mt-1 mb-5'>
          {ads.map((ad) => (
            <Col key={ad._id} lg={3} md={6} sm={12} className="mb-4">
              <Card>
                <Card.Img
                  variant="top"
                  src={ad.adImg || 'https://via.placeholder.com/150'}
                  alt={ad.title}
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                />
                <Card.Body>
                  <Card.Title>{ad.title}</Card.Title>
                  <Card.Text>
                    <strong>Forfatter:</strong> {ad.author} <br />
                    <strong>Pris:</strong> {ad.price} kr. <br />
                    <strong>Tilstand:</strong> {ad.condition} <br />
                  </Card.Text>
                  <Button
                    className="ad-item-edit-button mt-3"
                    href={`/bøger/annonce/${ad.shortAdId}`}
                  >
                    Se annonce
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}

export default ProfileAdsDisplay;