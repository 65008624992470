import React from "react";
import axios from "axios";
import "./App.css";
import NavBar from "./components/navbar";
import { Container, Form, Button } from "react-bootstrap";
import FrontPage from "./landingspages/frontpage";
import Footer from "./components/footer";
import SearchPage from "./landingspages/searchpage/searchpage";
import SignupPage from "./landingspages/signuppage/signuppage";
import LoginPage from "./landingspages/loginpage/loginpage";
import CreateAdPage from "./landingspages/createadpage/createadpage";
import AdPage from "./landingspages/adpage";
import EditAds from "./landingspages/editads/editads";
import Editprofile from "./landingspages/editprofile/editprofile";
import VerifyEmailPage from "./landingspages/verificationpage";
import ProfileAdsDisplay from "./landingspages/profileadsdisplay";
import { Routes, Route} from "react-router-dom";

function App() {
  return (
    <div className="App ">
             <div className="border-bottom pb-1">
            <Container>
            <NavBar />
            </Container>
          </div>
        <div className="div-height-hundred-vh pt-4">
      
 
    
    
    <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/bøger" element={<SearchPage/>} />
            <Route path="/bøger/opret-annonce" element={<CreateAdPage/>} />
            <Route path="/bøger/annonce/:shortAdId" element={<AdPage/>} />
            <Route path="/profil/:shortUserId" element={<ProfileAdsDisplay/>} />
            <Route path="/profil/opret" element={<SignupPage/>} />
            <Route path="/profil/login" element={<LoginPage/>} />
            <Route path="/profil/annoncer" element={<EditAds/>} />
            <Route path="/profil/rediger" element={<Editprofile/>} />
            <Route path="/verificer-email" element={<VerifyEmailPage/>} />
    </Routes> 
    
   </div> <Footer /> </div>
  );
}

export default App;
