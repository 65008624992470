import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

function EditAds() {
  const [ads, setAds] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [user, setUser] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);

  const navigate = useNavigate();

  const fetchUserAds = async (userId) => {
    console.log("Fetching user ads...");
    try {
      const response = await axios.get(`/api/books/${userId}/ads`);
      if (response.data.success) {
        setAds(response.data.ads);
        console.log("User Ads:", response.data.ads); // Debugging log
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/currentuser", {
          withCredentials: true,
        });

        if (response.data.status) {
          console.log(response.data.id);
          setUser(response.data);
          fetchUserAds(response.data.id);
        } else {
          navigate('/profil/login');
        }
      } catch (error) {
        console.error(error);
        navigate('/profil/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleEditAd = (ad) => {
    setSelectedAd(ad);
  };

  const handleDeleteAd = async () => {
    if (!user || !adToDelete) {
      console.error("User or adToDelete is not defined");
      return;
    }

    try {
      setLoading(true);

      const response = await axios.delete(`/api/books/${user.id}/ads/${adToDelete}`, {
        withCredentials: true,
      });
      if (response.data.success) {
        fetchUserAds(user.id);
        setShowDeleteModal(false);
        setAdToDelete(null);
      } else {
        alert("Noget gik galt under sletning af annoncen");
      }
    } catch (error) {
      alert("Noget gik galt under sletning af annoncen");
    } finally {
      setLoading(false);
    }
  };

  const handleShowDeleteModal = (adId) => {
    setAdToDelete(adId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setAdToDelete(null);
  };

  const handleUpdateAd = async (e) => {
    e.preventDefault();

    if (!user || !selectedAd) {
      console.error("User or selectedAd is not defined");
      return;
    }

    try {
      setLoading(true);
      console.log("Updating ad with data:", selectedAd); // Debugging log
      const response = await axios.put(`/api/books/${user.id}/ads/${selectedAd._id}`, {
        title: selectedAd.title,
        author: selectedAd.author,
        releaseYear: selectedAd.releaseYear,
        isbn: selectedAd.isbn,
        price: selectedAd.price,
        condition: selectedAd.condition,
        description: selectedAd.description,
        zipcode: selectedAd.zipcode,
        pickup: selectedAd.pickup,
        delivery: selectedAd.delivery,
        adImg: selectedAd.adImg,
        sellerPhone: selectedAd.sellerPhone,
        sellerMail: selectedAd.sellerMail,
        sellerId: selectedAd.sellerId,
        sellerName: selectedAd.sellerName,
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.success) {
        setSubmitSuccess(true);
        setTimeout(() => {
          setSubmitSuccess(false);
          setSelectedAd(null);
          fetchUserAds(user.id);
        }, 1000);
      } else {
        alert("Noget gik galt under opdatering af annoncen");
      }
    } catch (error) {
      alert("Noget gik galt under opdatering af annoncen");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('da-DK', options);
  };

  return (
    <Container className='mb-5'>
      <div className='row mt-5'>
        <div className='col-1'></div>
        <div className='col-11 mb-5'>
          <h1>Dine annoncer</h1>
          <p className='mb-5'>Her kan du se, redigere og slette dine bogannoncer.</p>
          <Row>
            {ads.map(ad => (
              console.log("Rendering ad:", ad),
              <Col key={ad._id} lg={4} className="mb-4">
                <div className="mt-4 pt-3 pb-3 buttom-border">
                  <Link className="dark-link" to={`/bøger/annonce/${ad.shortAdId}`}>
                    <h5>{ad.title}</h5>
                  </Link>
                  <p>Oprettet: {formatDate(ad.createdAt)}</p>
                  <Button className="mb-4 mt-4 ad-item-edit-button" onClick={() => handleEditAd(ad)}>Rediger</Button>
                  <Button className="mb-4 mt-4 ms-2 ad-item-delete-button" onClick={() => handleShowDeleteModal(ad._id)}>Slet</Button>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      {selectedAd && (
        <Modal show={true} onHide={() => setSelectedAd(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Rediger Annonce</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUpdateAd}>
              <Form.Group controlId="formTitle" className='mb-3'>
                <Form.Label>Titel</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Indtast titel på bogen"
                  value={selectedAd.title}
                  onChange={(e) => setSelectedAd({ ...selectedAd, title: e.target.value })}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDescription" className='mb-3'>
                <Form.Label>Beskrivelse</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Indtast beskrivelse"
                  rows={6}
                  value={selectedAd.description}
                  onChange={(e) => setSelectedAd({ ...selectedAd, description: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formPrice" className='mb-3'>
                <Form.Label>Pris</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Indtast pris i kr."
                  value={selectedAd.price}
                  onChange={(e) => setSelectedAd({ ...selectedAd, price: e.target.value })}
                  required
                />
              </Form.Group>
              <Button className="ad-item-edit-button" type="submit" disabled={loading}>
                {loading ? 'Opdaterer...' : 'Opdater Annonce'}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      {submitSuccess && (
        <div className="alert alert-success mt-3">
          Din annonce er blevet opdateret.
        </div>
      )}

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Bekræft Sletning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på, at du vil slette denne annonce?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Annuller
          </Button>
          <Button variant="danger" onClick={handleDeleteAd}>
            Slet
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EditAds;